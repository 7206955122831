import React from 'react';
import {Link} from 'react-router-dom';

import HeaderImage from "../../assets/images/banner-grid-square.webp";
import Farm2Survey from "../../assets/images/farm-2-survey.webp";

function App() {
    const title = 'Pilot Farm 2';
    document.title = global.AppName + ' - ' + title;
    
    return (
        <>
            <article id="main">
                <header style={{ backgroundImage: `linear-gradient(to top, rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(${HeaderImage})` }}>
                    <h2>{title}</h2>
                    {/* <p></p> */}
                </header>
                <section className="wrapper style5">
                    <div className="inner">
                        <section>
                            <p>
                                A 110ha farm from 125 to 165 m asl with 40m of elevation above a river in the North Cotswolds, not currently managed under any stewardship agreement.
                                It is a mixed farm, with around half arable and half permanent pasture, comprising a mix of unimproved grassland and semi-improved grassland. 
                                The landscape is typical of this area of the Cotswolds, with a dry valley of largely unimproved calcareous grassland running along the edge of the 
                                farm, horse paddocks, arable fields and small farmland copses in the central area and a river valley running along two of the farm's 
                                boundaries, with both improved pasture and former water meadows. There is no registered ancient woodland within the farm area.
                            </p>
                            <h3>Vegetation Survey</h3>                        
                            <span className="image centre full"><img src={Farm2Survey} alt="Grid Square Survey Farm 2" /></span>
                            <p>
                                A key feature at the farm for conservation is that there is an extensive remnant of about 20 hectares of continuous grassland along the slopes of a dry valley that 
                                appears to not to have been fertilised. Limestone grassland wildflower indicators were found and mapped, their current distribution is sparse and included a small 
                                number of Green-winged Orchid. Winter grazing success to reduce grass thatch will see the number of 25x25m grid squares with flowers such as Bird's-foot 
                                Trefoil spreading. Orchids, including Common-spotted and Pyramidal which have a patchy distribution across the farm, require mycorrhizal fungi as partners. These are 
                                lost if soil is disturbed or improved, indicating patches of undisturbed grassland on other grass paddocks and the driveway.
                            </p>
                            <h3>Insect Surveys</h3>
                            <p>
                                Trapping flying insects on the plateau of the limestone grassland restoration field confirmed there was an abundant grassland insect assemblage 
                                dominated by small moths (likely grass feeders) and Click Beetles whose larvae are soil dwelling. The caveat being that richness means relative to 
                                the series of such samples taken by Evidence Nature (13) to date. All Evidence Nature insect samples are standardised and therefore comparable with 
                                each other. As the data set grows, so will estimates of how many insects there should be in a healthy habitat. What we do now have is a baseline of 
                                insect diversity and abundance against which we expect to see a future increase under conservation grazing.
                            </p>
                            <h3>Bird Surveys</h3>
                            <p>
                                Good numbers of the commoner farmland birds (in particular Linnet, Skylark and Yellowhammer) were recorded, often making use of the 
                                wildflower-rich margins and areas, and a single Quail was also noted singing on one survey. The river held Cormorant, Moorhen, 
                                Mallard and Tufted Duck, and a large flock of wintering Siskin were present in the riparian alders in the river meadows.
                            </p>
                            <p>
                                A total of 46 species were encountered within the survey area during both the winter and the two breeding visits, including six 
                                red-listed species and 14 amber-listed species.
                            </p>
                        </section>
                    </div>
                    <div className='clear'></div>
                </section>
            </article>
        </>
    );
}

export default App;