import React from 'react';
import HeaderImage from "../../assets/images/banner-grid-square.webp";
import {Link} from 'react-router-dom';

import Farm1 from "../../assets/images/thumbnail-farm-1.webp";
import Farm2 from "../../assets/images/thumbnail-farm-2.webp";

function App() {
    const title = 'Pilot Farms';
    document.title = global.AppName + ' - ' + title;
    
    return (
        <>
            <article id="main">
                <header style={{ backgroundImage: `linear-gradient(to top, rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(${HeaderImage})` }}>
                    <h2>{title}</h2>
                    <p>The pilot farms used in the FiPL project</p>
                </header>
                <section className="wrapper style5">
                    <div className="inner">
                        <section>
                            <p>
                                The Cotswold Parataxonomy Farming in a Protected Landscape (FiPL) project was run for two pilot farms in The Cotsolds. Summary results can be 
                                found for each farm. The material is copyrighted to the authors <Link to='/the-experts/bios#Kathy' title='Kathy Meakin'>Kathy Meakin</Link> and <Link to='/the-experts/bios#Anna' title='Anna Field'>Anna Field</Link>.
                            </p>
                            <section className="features">
                                <section className="feature">
                                    <Link to='/pilot-farms/farm-1' title='Farm 1' target='_self'>
                                        <img src={Farm1} alt="Farm 1" />
                                    </Link>
                                    <div>
                                    <h4><Link to='/pilot-farms/farm-1' title='Farm 1' target='_self'>Farm 1</Link></h4>
                                    <Link to='/pilot-farms/farm-1' title='Farm 1' target='_self'>Find out more...</Link>
                                    </div>
                                </section>
                                <section className="feature">
                                    <Link to='/pilot-farms/farm-2' title='Farm 2' target='_self'>
                                    <img src={Farm2} alt="Farm 2" />
                                    </Link>
                                    <div>
                                    <h4><Link to='/pilot-farms/farm-2' title='Farm 2' target='_self'>Farm 2</Link></h4>
                                    <Link to='/pilot-farms/farm-3' title='Farm 2' target='_self'>Find out more...</Link>
                                    </div>
                                </section>
                            </section>
                        </section>
                    </div>
                    <div className='clear'></div>
                </section>
            </article>
        </>
    );
}

export default App;