import React from 'react';
import {Link} from 'react-router-dom';
import Header from "../../components/header";
import Footer from "../../components/footer";
import HeaderImage from "../../assets/images/banner-grid-square.webp";
import InsectSortingTables from "../../assets/images/insect-sorting-tables.webp";

function App() {
    const title = 'Home';
    document.title = global.AppName;
    return (
        <>
            <Header></Header>
            <article id="main">
                <header style={{ backgroundImage: `linear-gradient(to top, rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(${HeaderImage})` }}>
                    <h2>Error</h2>
                    <p>The page wasn't found</p>
                </header>
                <section className="wrapper style5">
                    <div className="inner">
                    <section>
                        <h3>404 - Page No Found</h3>
                        <p>
                            The page you were looking for wasn't found. Use the menu above to find what you are looking for. If the problem continues 
                            then <Link to='contact-us' title='Contact Page'>contact us</Link> to let us know.
                        </p>
                        <div className='clear'></div>
                    </section>
                    </div>
                </section>
            </article>
            <Footer></Footer>
        </>
    );
}

export default App;