import React, { Component } from "react";
import { NavLink } from 'react-router-dom';
import './index.css';

function Header({ currentPage }) {
    function toggleMobileMenu() {
        var x = document.getElementById("mobileMenu");
        x.classList.toggle("show")
    }
    return (
        <>
        {
            process.env.NODE_ENV === 'development' && 
            <div id="devbuild">
            This is a Dev Build
            </div>
        }
        <header id="header">
            <nav>
            <NavLink title="Mobile menu" to="#menu" className="menuToggle" onClick={toggleMobileMenu}><span>Menu</span></NavLink>
            <ul id="mobileMenu" className="menu">
                <li><NavLink to="/" onClick={toggleMobileMenu}>Home</NavLink></li>            
                <li className="dropdown">
                    <NavLink to="/biodiversity-surveys" onClick={toggleMobileMenu}>Surveys</NavLink>
                    <ul>
                        <li><NavLink to="/biodiversity-surveys/vegetation" onClick={toggleMobileMenu}>Vegetation</NavLink></li>
                        <li><NavLink to="/biodiversity-surveys/insect" onClick={toggleMobileMenu}>Insect</NavLink></li>
                        <li><NavLink to="/biodiversity-surveys/bird" onClick={toggleMobileMenu}>Bird</NavLink></li>
                        <li><NavLink to="/biodiversity-surveys/dung-beetle" onClick={toggleMobileMenu}>Dung Beetle</NavLink></li>
                    </ul>
                </li>
                <li className="dropdown">
                    <NavLink to="/pilot-farms" onClick={toggleMobileMenu}>Pilot Farms</NavLink>
                    <ul>
                        <li><NavLink to="/pilot-farms/farm-1" onClick={toggleMobileMenu}>Farm 1</NavLink></li>
                        <li><NavLink to="/pilot-farms/farm-2" onClick={toggleMobileMenu}>Farm 2</NavLink></li>
                    </ul>
                </li>              
                <li><NavLink to="/conservation-measures" onClick={toggleMobileMenu}>Conservation Measures</NavLink></li>
                <li className="dropdown">
                    <NavLink to="/the-experts" onClick={toggleMobileMenu}>The Experts</NavLink>
                    <ul>
                        <li><NavLink to="/the-experts/bios" onClick={toggleMobileMenu}>Bios</NavLink></li>
                        <li><NavLink to="/the-experts/contact-us" onClick={toggleMobileMenu}>Contact Us</NavLink></li>
                    </ul>
                </li>
                <li id="close" onClick={toggleMobileMenu}>
                <NavLink to="#menu"><span>Close</span></NavLink>
                </li>
            </ul> 
            </nav>
        </header>  
        <noscript>You need to enable JavaScript to run this app.</noscript>
        </>        
    );
  }
    
  export default Header;

