import React from 'react';
import {Link} from 'react-router-dom';

import HeaderImage from "../../assets/images/banner-farm-1.webp";
import LadysBedstraw from "../../assets/images/ladys-Bedstraw.webp";

function App() {
    const title = 'Pilot Farm 1';
    document.title = global.AppName + ' - ' + title;
    
    return (
        <>
            <article id="main">
                <header style={{ backgroundImage: `linear-gradient(to top, rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(${HeaderImage})` }}>
                    <h2>{title}</h2>
                    {/* <p></p> */}
                </header>
                <section className="wrapper style5">
                    <div className="inner">
                        <section>
                            <p>
                                A 140ha hillside farm farm of large pastures rising gradually over 100 metres of elevation mostly to the north of an incised dry valley managed 
                                under mid-tier countryside stewardship agreement. Other than several plots of AB9 (Winter Bird Food) the farm is entirely permanent 
                                grassland and the lack of arable will likely limit the range farmland bird species present. The farm is partially surrounded by a large area 
                                of registered ancient woodland and the bird species present are likely to be strongly influenced by this. 
                            </p>
                            <h3>Vegetation Survey</h3>
                            <span className="image right"><img src={LadysBedstraw} alt="Lady's Bedstraw" /></span>
                            <p>
                                Some fields have a history of changing from pasture to arable and back again. Grazing had lapsed barring a trial of rotational tall grass grazing in 2022.
                                Patchy thin soil over brashy limestone suggests the sort of free-draining low nutrient soils that cattle grazing could restore priority species-rich 
                                grassland over steeper slopes. Vegetation mapping has shown where drifts of the more persistent limestone wildflowers pick out these ground conditions 
                                and highlighted how the ecology of a field should not necessarily be defined by its boundaries but by its the underlying landform and soils.
                            </p>
                            
                            <p>
                                Past arable cultivation driven by previous economic incentives in one field has reverted to a grassland with an unusual vegetation structure that 
                                does not conform to standard vegetation classification. The field has become an unusual mix of possible re-established remnant grassland 
                                (Pyramidal & Bee Orchids which would be suitable for grid-square mapping, Bird's-foot Trefoil and limestone-specific Tor Grass), 
                                interspersed with early successional bare ground patches with moss patches visible at the height of the growing season. 
                                The well-established tall and tussock-forming grasses would usually out-compete wild flowers. This mixed sward height is the effect we wanted to 
                                look for in mob grazing , but achieved here in an older arable reversion, raising interesting and probably little studied questions about how 
                                long plant communities take to develop after reversion and if grass productivity can be maintained alongside flowering plant richness. 
                                Arguably this is closer to the natural grazing regime these plants evolved under.
                            </p>
                            <h3>Insect Surveys</h3>
                            <p>
                                The hill-top pasture of one of the sites, which apart from a period of mob-grazing last year, has gone largely un-grazed, was one of the richer sites 
                                for flying insects sampled to date in Evidence Nature's 
                                standard <Link to='/biodiversity-surveys/insect' title='Insect Surveys' target='_self'>insect trapping studies</Link>. Trapping found that there 
                                were slightly more insects &lt;5mm both in terms of number of species and abundance on the exposed hill top with thin soil, and more flowering plants 
                                than in a shallow dip dominated by tall grass, where small insects, 5mm were much more prevalent. But it was a marginal difference 
                                un-replicated at this stage. A truer estimate of the variety of insects present requires further sorting. Catches at both locations were dominated 
                                by flies that lay their eggs in carrion (flesh and blow flies), and miscellaneous beetles, many occurring singly leading to the being grouped as 
                                miscellaneous, though flower beetles, Summer Chafers, dung beetles and a species of Longhorn beetle associated with grassland fungus were noted in 
                                passing, suggesting grassland insects were frequently passing through and encountering one or two traps more frequently than others 
                                (though not disproportionally the attractant yellow ones) .
                            </p>
                            <h3>Bird Surveys</h3>
                            <p>
                                Good numbers of Skylark were recorded throughout the year and Linnet was recorded breeding in smaller numbers. The wooded nature of the farm and lack of 
                                arable has a major impact on the farmland bird species seen here as the habitat is unsuitable for open-farmland loving species such as Corn Bunting, 
                                Lapwing and Yellow Wagtail. However, a number of woodland species of note were recorded, including Firecrest, Goshawk, Redstart, Siskin, Spotted 
                                Flycatcher and Woodcock.
                            </p>
                            <p>
                                No Barn Owls were recorded during the survey and this is perhaps because the farm is fairly wooded rather than open farmland but, as there is plenty 
                                of suitable grassland habitat and nest boxes installed, it is possible they will breed there in the future.
                            </p>
                            <p>A total of 50 species were encountered within the survey area during both the winter and two breeding visits, including 9 red-listed and 15 amber-listed birds.</p>
                        </section>
                    </div>
                    <div className='clear'></div>
                </section>
            </article>
        </>
    );
}

export default App;