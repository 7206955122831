import React from 'react';
import {Link} from 'react-router-dom';

import HeaderImage from "../../assets/images/banner-samples-2.webp";

function App() {
  const title = 'Conservation Measures';
  document.title = global.AppName + ' - ' + title;
  return (
    <>
        <article id="main">
            <header style={{ backgroundImage: `linear-gradient(to top, rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(${HeaderImage})` }}>
                <h2>{title}</h2>
                <p>What steps can you take to increase biodiversity</p>
            </header>
            <section className="wrapper style5">
                <div className="inner">
                    <section>
                        <h3>Birds</h3>   
                        <p>
                            As discussed in more detail 
                            under <Link to='/biodiversity-surveys/bird' title='Bird Surveys' target='_self'>Bird Surveys</Link>, 
                            farmland birds such as Skylark, Corn Bunting, Yellowhammer and Grey Partridge have all seen serious population declines over the last five decades, 
                            for example Grey Partridge has declined by 92% since the 1960s. Nearly all farmland birds require three things: 
                        </p>
                        <ol>
                            <li>nesting habitat (usually on the ground in crops or in hedgerows)</li>
                            <li>seeds and grains to feed adults throughout the year and</li>
                            <li>invertebrates during the breeding season to feed chicks</li>
                        </ol>
                        <p>
                            Unfortunately, agricultural intensification has reduced the availability of all of these as a result of habitat loss, increased efficiency 
                            of harvesting and herbicide and pesticide use.
                        </p>
                        <p>
                            By understanding what species are present on a farm and where, it is possible to target habitat management to increase these species e.g. 
                            improved hedgerow management where Yellowhammer and Linnet are found or designing tree planting schemes away from strongholds of species 
                            that love open landscapes such as Corn Bunting and Lapwing. There are also a number of arable options in Countryside Stewardship which 
                            are designed to help farms meet the requirements of farmland birds, for example AB1 (Nectar flower mix) aims to deliver habitat and nectar 
                            for invertebrates during the flower season and AB9 (Winter Bird Food) funds the creation of plots or strips of seed rich plants and aims 
                            to provide seed to birds during the hard winter months. There are many more, including species-specific options such as AB4 (Skylark plots) 
                            to support Skylark. 
                        </p>
                        <p>
                            If you would like support in understanding what farmland birds species you have on your farm and advice on the most appropriate habitat management 
                            and Countryside Stewardship options, please do get in touch with Anna  
                            at <Link to="https://cotswoldbirdsurveys.org.uk/contact-us" title='Cotswold Bird Surveys' target="_blank"> Cotswold Bird Surveys</Link>.
                        </p>                        
                        <div className='clear'></div>
                    </section>
                </div>
            </section>
        </article>
    </>
  );
}

export default App;