import React from 'react';
import {Link} from 'react-router-dom';

import HeaderImage from "../../assets/images/banner-vegetation.webp";
import BeeOrchid from "../../assets/images/bee-orchid.webp";
import Farm1Survey from "../../assets/images/farm-1-survey.webp";

function App() {
  const title = 'Vegetation Surveys';
  document.title = global.AppName + ' - ' + title;
  return (
    <>
        <article id="main">
            <header style={{ backgroundImage: `linear-gradient(to top, rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(${HeaderImage})` }}>
                <h2>{title}</h2>
                <p>Vegetation surveys using the Grid Square survey methodology</p>
            </header>
            <section className="wrapper style5">
                <div className="inner">
                    <section>
                        <h3>Grid Square Survey Methodology</h3>
                        <span className="image right"><img src={BeeOrchid} alt="Bee Orchid" /></span>
                        <p>
                            The standard vegetation survey is the quadrat method. While this has been used and is scientifically validated it does have some drawbacks. The key issues for
                            farmers is that it requires extensive knowledge of plant species to be able to identify all species in the quadrat, something most farmers will not 
                            easily be able to do. Also, the survey method is an industry standard for identifying species in a location but it doesn't help with monitoring 
                            distribution of species across a field over time. The Grid Square Survey Methodology solves both of these problems.
                        </p>
                        <p>
                            Created by <Link to='/the-experts/bios#Kathy' title='Kathy Meakin'>Kathy Meakin</Link>, the Grid Square Methodology (GSM) is an easy method of mapping 
                            distribution of key species across an area and, in particular, gives the ability to monitor changes in distribution of the species over time. It is 
                            a simple survey method that can be undertaken but non-expert 'para-ecologists' and gives farmers the ability to monitor the impact of their 
                            grassland management over time. 
                        </p>
                        <p>
                            The first step is to identify a small number of key indicator species, or species of interest to monitor. Often it is helpful to engage with an 
                            ecologist at this stage to set up the project and look at the holistic view of the farm to see what you are trying to achieve. By setting goals and 
                            objectives the species to be monitored can be selected and an ecologist can train the non-expert user in how to identify the plant accurately. Examples 
                            of species to monitor could be Bee Orchids, Yellow Rattle or 'negative species' like nettles and bracken.
                        </p>
                        <p>
                            Once the species to be monitored have been identify, relevant fields are selected and the size of the grid squares that the fields will be split into is 
                            selected. The fields will be split into grids squares based on the Ordinance Survey coordinate system. Squares could be 10m x 10m, 25m x 25m or other 
                            practical dimensions depending on the size of the field and abundance of the species. They need to be big enough to make the survey viable but not 
                            too big so that fidelity of survey results is lost. 
                        </p>
                        <span className="image centre"><img src={Farm1Survey} alt="Grid Square Survey" /></span>
                        <p>
                            Once the grid has been defined and overlaid on a map the site is ready for surveying. Choose a key to use to mark the maps for the species you are 
                            monitoring, e.g. red dot for Bird's-foot trefoil, pink pyramid for Pyramidal Orchid, T for Tor Grass and blue dot for Broomrape.
                        </p>
                        <p>
                            The simple concept of the survey is a walk over survey, walking the grid squares counting the number of plants of they key species in each square. 
                            There are a number of mobile phone apps that will show you the OS coordinates for your location, make sure you have the app set to show 10 digit 
                            coordinates giving you your location to the nearest meter. Walking the map identify the centre of a specific square and then walk the square counting
                            the number of the species for that square. Mark on your map any of the monitored species using the key, one mark for each individual plant. Walk to the 
                            next grid square and repeat. 
                        </p>
                        <p>
                            If the species being monitored are more abundant than individual plants you can mark the boundary of an area of distribution, see rank grass (green hash) 
                            in the sample above. The other option is rather than mark individual species or boundaries you can use a count in the cell instead e.g red 10 for 10 
                            Bird's-foot trefoil, or a scale e.g red 1, 2, 3, 5, 10, 20, + for a rough count per square (individuals, handful, lots or too many to count). The 
                            key is consistency, make sure you use the same scale / method of marking distribution across the survey for a species and in subsequent years. 
                        </p>
                        <p>
                            For each walk over you can map 3 or 4 species at a time, and after some practice each square won't take too long to record. Keep good records and 
                            repeat the survey at the same time of year each year to see how the distribution of key species changes over time and with your grassland management 
                            practices. 
                        </p>
                    </section>
                    <div className='clear'></div>
                </div>
            </section>
          </article>
    </>
  );
}

export default App;