import React from 'react';
import {Link} from 'react-router-dom';

import HeaderImage from "../../assets/images/banner-grid-square.webp";
import Vegetation from "../../assets/images/thumbnail-bios.webp";
import Insect from "../../assets/images/thumbnail-insect-2.webp";

function App() {
  const title = 'The Experts';
  document.title = global.AppName + ' - ' + title;
  return (
    <>
        <article id="main">
            <header style={{ backgroundImage: `linear-gradient(to top, rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(${HeaderImage})` }}>
                <h2>{title}</h2>
                <p>The Experts involved in the project</p>
            </header>
            <section className="wrapper style5">
                <div className="inner">
                    <section>
                        <h3>Meet the experts</h3>
                        <p>
                            The Cotswold Parataxonomy Farming in a Protected Landscape (FiPL) project was run by ecology experts. 
                            Find out more about the team and contact them about the project.
                        </p>
                        <section className="features">
                            <section className="feature">
                                <Link to='/the-experts/bios' title='Bios' target='_self'>
                                    <img src={Vegetation} alt="Bios" />
                                </Link>
                                <div>
                                <h4><Link to='/the-experts/bios' title='Bios' target='_self'>Bios</Link></h4>
                                <p>
                                    Read about the team of experts working on the project.
                                </p>
                                <Link to='/the-experts/bios' title='Bios' target='_self'>Find out more...</Link>
                                </div>
                            </section>
                            <section className="feature">
                                <Link to='/the-experts/contact-us' title='Contat us' target='_self'>
                                <img src={Insect} alt="Contact us" />
                                </Link>
                                <div>
                                <h4><Link to='/the-experts/contact-us' title='Contact us' target='_self'>Contact us</Link></h4>
                                <p>
                                    Get in touch with the team to find out more.
                                </p>
                                <Link to='/the-experts/contact-us' title='Contact us' target='_self'>Find out more...</Link>
                                </div>
                            </section>
                        </section>   
                        <div className='clear'></div>      
                    </section>
                </div>
            </section>
          </article>
    </>
  );
}

export default App;