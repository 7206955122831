import React from 'react';
import {Link} from 'react-router-dom';

import HeaderImage from "../../assets/images/banner-samples-2.webp";

function App() {
  const title = 'Flying Insect Surveys';
  document.title = global.AppName + ' - ' + title;
  return (
    <>
        <article id="main">
            <header style={{ backgroundImage: `linear-gradient(to top, rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(${HeaderImage})` }}>
                <h2>{title}</h2>
                <p>Flying insect trap surveying</p>
            </header>
            <section className="wrapper style5">
                <div className="inner">
                  <section>
                    <h3>Coming soon...</h3>
                  </section>
                </div>
            </section>
            <div className='clear'></div>
          </article>
    </>
  );
}

export default App;