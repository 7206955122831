import React from 'react';
import {Link} from 'react-router-dom';
import HeaderImage from "../../assets/images/banner-grid-square.webp";
import InsectSortingTables from "../../assets/images/insect-sorting-tables.webp";

function App() {
    const title = 'Home';
    document.title = global.AppName;
    return (
        <>
            <article id="main">
                <header style={{ backgroundImage: `linear-gradient(to top, rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(${HeaderImage})` }}>
                    <h2>Cotswold Parataxonomy</h2>
                    <p>The Cotswold FiPL Biodiversity Project</p>
                </header>
                <section className="wrapper style5">
                    <div className="inner">
                    <section>
                        <h3>Biodiversity Crisis</h3>
                        <p>
                            One of the major issues that we find ourselves facing in the modern world is the Biodiversity Crisis. In a nutshell this is the loss of biodiversity of
                            species on the planet driven by loss of habitat, modern farming practices and the use of chemicals in the environment; amongst other causes. 
                            The implications of biodiversity loss may be far reaching and impact us all.
                        </p>
                        <p>
                            Farmers and landowners often get the finger pointed at them for 'causing' the biodiversity crisis but in many cases were only following the government policy at the 
                            time and, with the financial support of environmental land management schemes, are increasingly willing to be part of the solution. Many farmers in the Cotswolds 
                            are already heavily invested in increasing biodiversity on their farms and this project seeks to support these farmers further as well as those just starting out on 
                            their journey.
                        </p>
                        <p>
                            This project was born out of the desire to broaden awareness within the farming community about biodiversity on farm.
                        </p>
                        <h3>Project Scope</h3>
                        <span className="image right"><img src={InsectSortingTables} alt="Insect sorting workshop" /></span>
                        <p>
                            In 2023 <Link to='/the-experts/bios#James' title='James Allen'>James Allen</Link>, at the time a grazier in the Cotswolds, applied for a Farming in a Protected 
                            Landscape (FiPL) grant to empower farmers / landowners to be able to monitor the biodiversity on their farms.
                        </p>
                        <p>
                            Working with <Link to='/the-experts/bios#Kathy' title='Kathy Meakin'>Kathy Meakin</Link>, ecologist and project lead, 
                            and <Link to='/the-experts/bios#Anna' title='Anna Field'>Anna Field</Link>, ecologist and ornithologist of Cotswold Bird Surveys, a FiPL grant was applied for 
                            to run biodiversity surveys across two <Link to='/pilot-farms' title='the pilot farms'>pilot farms</Link> in The Cotswolds. The objective of the project was 
                            to test and implement biodiversity studies across the farms, including looking at 'farmer level' surveying methods that would allow farmers to do ongoing 
                            monitoring of changes in biodiversity.
                        </p>
                        <p>
                            One of the monitoring techniques trialed was the <Link to='/biodiversity-surveys/vegetation' title='Grid Square Survey Methodology'> Grid Square Survey Methodology</Link> 
                            for vegetation surveying pioneered by <Link to='/the-experts/bios#Kathy' title='Kathy Meakin'>Kathy</Link>. This enables farmers to walk their fields and record 
                            key indicator species allowing to monitor changes over time.
                        </p>
                        <h3>Parataxonomy</h3>
                        <p>
                            Parataxonomy is the use of non-fully qualified people to record species observations to enable larger scale recordings without the need for 
                            specialist entomologist recorders. First coined in Costa Rica to increase the capacity of insect surveys it was used in the project in 
                            the <Link to='biodiversity-surveys/insect' title='Insect surveys'>insect surveys</Link>. This 'citizen science' methodology has also been used in 
                            the <Link to='biodiversity-surveys/vegetation' title='Vegetation surveys'>vegetation surveys</Link>.
                        </p>
                        <p>
                            By utilising farmers to monitor and survey their own land you not only empower and engage farmers with biodiversity on their own land but you 
                            also increase the number of people monitoring biodiversity. There aren't enough ecologists to monitor all the land in the UK so using land 
                            owners and managers increases the monitoring significantly.
                        </p>
                        <p>
                            But non-expects need guidance and focusing, they need to know what to look for and what outcomes mean. Parataxonomy is nothing without ecologist to add focus and validity to 
                            the work done by the broader public.
                        </p>
                        <div className='clear'></div>
                    </section>
                    </div>
                </section>
            </article>
        </>
    );
}

export default App;