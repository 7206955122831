import React from 'react';
import {Link} from 'react-router-dom';

import HeaderImage from "../../assets/images/banner-birds.webp";
import BirdTrends from "../../assets/images/bird-trends.webp";
import Linnet from "../../assets/images/linnet.webp";


function App() {
    const title = 'Bird Surveys';
    document.title = global.AppName + ' - ' + title;
    return (
        <>
            <article id="main">
                <header style={{ backgroundImage: `linear-gradient(to top, rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(${HeaderImage})` }}>
                    <h2>{title}</h2>
                    <p>Three count, annual bird surveying</p>
                </header>
                <section className="wrapper style5">
                    <div className="inner">
                        <section>
                            <h3>Farmland bird surveys</h3>
                            <p>
                                Each year, the BTO, together with RSPB and JNCC, produce Wild Bird Indicators for DEFRA. These are based largely on population trends calculated using 
                                data from volunteer bird monitoring schemes such as the BTO/JNCC/RSPB Breeding Bird Survey. As well as an overall indicator covering species from all 
                                habitats, indicators are produced for individual habitats, including a farmland bird indicator, which sadly shows a severe decline in farmland birds 
                                since 1970. The graph also shows that specialist farmland birds such as Tree Sparrow and Corn Bunting have fared far worse than generalists such as 
                                Stock Dove and Goldfinch.
                            </p>
                            <span className='image centre'>
                                <img  src={BirdTrends} alt='Breeding farmland birds in the UK, 1970 to 2022'></img>
                            </span>
                            <div className='image-title'>
                                Breeding farmland birds in the UK, 1970 to 2022
                            </div>
                            <div className='citation'>
                                Source: <Link to='https://www.bto.org/our-science/publications/developing-bird-indicators#farmland' title='British Trust for Ornithology' target='_blank'>
                                    British Trust for Ornithology (BTO)</Link>, Royal Society for the Protection of Birds (RSPB), and the Joint Nature Conservation Committee (JNCC).
                            </div>                    
                            <p>                            
                                This huge loss of birds in our farmlands and their value as keystone species is why the pilot project includes bird survey as one of the metrics.
                            </p>
                            <h3>Methodology</h3>
                            <span className='citation image right'><img src={Linnet} alt="Linnet" />Photo credit and &copy; Rich Tyler</span>
                            <p>
                                A multi-visit breeding bird survey was undertaken in April-June which mapped the territories of all Core Farmland Species and red-listed species 
                                using a method similar to that used in the British Trust for Ornithology's (BTO) Common Bird Census (CBC) (Marchant 1983 & Bibby et al. 1992), 
                                altered slightly as a consequence of the reduced number of visits. Territory mapping in this way allows the distribution of bird territories across the survey area and habitat associations to be determined. Territory maps were digitised to permit a combined analysis with the vegetation and invertebrate surveys.
                            </p>
                            <p>
                                The Core Farmland Bird species mapped were Barn Owl, Corn Bunting, Grey Partridge, Lapwing, Linnet, Quail, Reed Bunting, Skylark, Tree Sparrow, 
                                Woodlark, Yellowhammer and Yellow Wagtail. Counts of all other bird species were recorded on each visit. Additionally, breeding evidence 
                                (confirmed, probable or possible) was recorded for each species.
                            </p>                        
                            <p>A winter bird survey was carried out between December and February to record winter visitors to each farm.</p>
                        </section>
                        <div className='clear'></div>
                    </div>
                </section>
            </article>
        </>
    );
}

export default App;