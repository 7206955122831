import React from 'react';
import {Link} from 'react-router-dom';
import './index.css';
import HeaderImage from "../../assets/images/banner-grid-square.webp";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitter } from '@fortawesome/free-brands-svg-icons'
import { faFacebook } from '@fortawesome/free-brands-svg-icons'
import { faInstagram } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { faGlobe } from '@fortawesome/free-solid-svg-icons'
import Kathy from "../../assets/images/kathy-meakin.webp";
import Anna from "../../assets/images/anna-field.webp";
import James from "../../assets/images/james-allen.webp";

function App() {
    const title = 'Experts';
    document.title = global.AppName + ' - ' + title;
    React.useEffect(() => {
        const anchor = window.location.hash.slice(1);
        if (anchor) {
            const anchorEl = document.getElementById(anchor);
            if (anchorEl) {
                anchorEl.scrollIntoView();
            }
        }
    }, []);
    return (
        <>
            <article id="main">
                <header style={{ backgroundImage: `linear-gradient(to top, rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(${HeaderImage})` }}>
                    <h2>{title}</h2>
                    <p>Find out about who worked on the project</p>
                </header>
                <section className="wrapper style5">
                    <div className="inner">
                        <h3>The Experts</h3>
                        <p>
                            The Cotswold Parataxonomy FiPL project was run by two local ecologists: 
                        </p>
                        <section className='profile' id='Kathy'>
                            <h4>Kathy Meakin</h4>
                            <div>
                                <img src={Kathy} alt='Kathy Meakin' />
                                <div>
                                    <p>
                                        Kathy lead the Parataxonomy project and completed the vegetation and flying insect surveys as well as running the flying insect workshop. 
                                        An ecologist she worked for the Gloucestershire Wildlife Trust for over 10 years and now offers scientific support and advice on 
                                        Biodiversity Net Gain and Nature Recovery to the public and private sectors as well as training and mentoring through her  
                                        consultancy <Link to='https://www.evidencenature.co.uk' title='Evidence Nature' target='_blank'>Evidence Nature</Link>. She has pioneered the Grid Survey 
                                        Methodology and runs ecology workshops. 
                                    </p>
                                    <ul className="icons">
                                        {/* <li><Link to="https://www.evidencenature.co.uk" className="icon" target="_blank"><FontAwesomeIcon icon={faGlobe} /><span className="label">Website</span></Link></li> */}
                                        <li><Link to="https://twitter.com/kathy_meakin" className="icon" target="_blank"><FontAwesomeIcon icon={faTwitter} /><span className="label">Twitter</span></Link></li>
                                    </ul>
                                </div>
                            </div>
                        </section>
                        <section className='profile' id='Anna'>
                            <h4>Anna Field</h4>
                            <div>
                                <img src={Anna} alt='Anna Field' />
                                <div>
                                    <p>
                                        Anna is an ecologist working for the Cotswolds National Landscape on a wildflower grassland restoration project called Glorious Cotswold Grasslands. Outside work her 
                                        passion is birds and she holds a British Trust for Ornithology 'A' Ringing Permit and works on a number of monitoring projects for the Gloucestershire Raptor Monitoring 
                                        Group and North Wiltshire Ringing Group. Through her freelance 
                                        business, <Link to='https://cotswoldbirdsurveys.org.uk' title='Cotswold Bird Surveys' target='_blank'>Cotswold Bird Surveys</Link>. Anna carries out a variety of bird 
                                        surveys for local farmers and landowners and completed territory mapping bird surveys on the two pilot farms for this project.
                                    </p>
                                    <ul className="icons">
                                        <li><Link to="https://cotswoldbirdsurveys.org.uk" title='Cotswold Bird Surveys website' className="icon" target="_blank"><FontAwesomeIcon icon={faGlobe} /><span className="label">Website</span></Link></li>
                                        <li><Link to="https://twitter.com/cotsbirdsurveys" title='Cotswold Bird Surveys twitter' className="icon" target="_blank"><FontAwesomeIcon icon={faTwitter} /><span className="label">Twitter</span></Link></li>
                                    </ul>
                                </div>
                            </div>
                        </section>
                        
                        <h3>The Sponsor</h3>
                        <p>
                            The Cotswold Parataxonomy project was funded by a FiPL grant applied by a local farmer, James Allen: 
                        </p>
                        <section className='profile' id='James'>
                            <h4>James Allen</h4>
                            <div>
                                <img src={James} alt='James Allen' />
                                <div>
                                    <p>
                                        James and his wife, Katie, are 1st generation farmers who started keeping livestock in 2015. They spent 8 years without a base for their livestock grazing for wildlife organisations, 
                                        councils and private landowners in the Cotswolds specialising in conservation grazing species rich wildflower meadows and regenerative grazing.
                                    </p>
                                    <p>
                                        They now have their own <Link to="https://www.greatcotmarshfarm.co.uk" title='Great Cotmarsh Farm' target="_blank">Great Cotmarsh Farm</Link> just 
                                        next to the Marlborough Downs, part of the North Wessex AONB, running around 40 head of British White and English Longhorn cattle along with around 250 Castlemilk 
                                        Moorit and Portland sheep. 
                                    </p>
                                    <p>
                                        They sell high quality beef and lamb direct to consumers through a box scheme and also produce hides and sheepskins for sale. Katie makes high quality wool produce from the wool off the sheep,
                                        having it processed in the South West and then selling them under the brand <Link to='https://www.loopyewes.co.uk' title='Loopy Ewes' target='_blank'>Loopy Ewes</Link>.
                                    </p>
                                        <ul className="icons">
                                            <li><Link to="https://twitter.com/heritageGrazier" className="icon" target="_blank"><FontAwesomeIcon icon={faTwitter} /><span className="label">Twitter</span></Link></li>
                                            <li><Link to="https://www.facebook.com/greatcotmarshfarm/" className="icon" target="_blank"><FontAwesomeIcon icon={faFacebook} /><span className="label">Facebook</span></Link></li>
                                            <li><Link to="https://www.instagram.com/greatcotmarshfarm/" className="icon" target="_blank"><FontAwesomeIcon icon={faInstagram} /><span className="label">Instagram</span></Link></li>
                                            <li><Link to="https://www.greatcotmarshfarm.co.uk/say-hello" className="icon" target="_blank"><FontAwesomeIcon icon={faEnvelope} /><span className="label">Email</span></Link></li>
                                        </ul>
                                </div>
                            </div>
                            <div className='clear'></div>
                        </section>
                    </div>
                </section>
            </article>
        </>
    );
}

export default App;