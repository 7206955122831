import React from 'react';
import {Link} from 'react-router-dom';

import HeaderImage from "../../assets/images/banner-dung-beetle.webp";
import FilePDF from "../../assets/images/file-pdf.webp";
import FileXLSX from "../../assets/images/file-xlsx.webp";

function App() {
  const title = 'Dung Beetles';
  document.title = global.AppName + ' - ' + title;
  return (
    <>
        <article id="main">
            <header style={{ backgroundImage: `linear-gradient(to top, rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(${HeaderImage})` }}>
                <h2>{title}</h2>
                <p>Want to know how dung beetle friendly you are?</p>
            </header>
            <section className="wrapper style5">
                <div className="inner">
                    <section>
                        <h3>Surveying for Dung Beetles</h3>
                        <p>
                            Dung beetles are a keystone specialist and are hugely important in the agricultural landscape providing a number of key benefits to farmers and the 
                            environment.
                        </p>
                        <p>
                            We did not run dung beetle assessments on the pilot farms but 
                            the <Link to={'https://www.dungbeetlesforfarmers.co.uk/'} title='Dung Beetles for Farmers' target='_blank'>Dung Beetles for Farmers website </Link> is a great 
                            resource which includes how non-entomologists can assess a site for dung beetles. They have kindly let us share the assessments here but go to their website 
                            for a wealth of information about dung beetles, why they are so beneficial and how to support them.
                        </p>
                        <h3>Warning</h3>
                        <p>
                            This is assessment is not a dung beetle survey, rather an site assessment that gives an indication of dung beetle activity at your site.
                        </p>
                        <p>
                            Has this assessment peaked your interest? Want to really know about the dung beetles you 
                            have? <Link to={'https://www.dungbeetlesforfarmers.co.uk/book-a-visit'} title='Contact Dung Beetles for Farmers' target='_blank'>Contact the Dung Beetles for Farmers Team</Link> to 
                            book a full survey conducted by an expert or have an expert come to talk to you about dung beetles and show you what you have.
                        </p>
                        <h3>The Assessment</h3>
                        <p>
                            This dung beetle assessment has been designed to help quantify how well a site supports dung beetles, both the number of the
                            different groups of dung beetles and the 'quality' of dung on the site.
                        </p>
                        <p>
                            Downloads of these instructions and the associated scorecards are below.
                        </p>
                        <h4>When:</h4>
                        <ul>
                            <li>2 periods of the year for each site (April-June & September-October)</li>
                            <li>Any time in the allocated months but not in, or within 24 hours of heavy rain</li>
                            <li>Any time of day</li>
                        </ul>
                        <h4>Where:</h4>
                        <ul>
                            <li>The habitat (i.e. a field) must have had grazing livestock feeding in the last 24 hours (fresh pats)</li>
                            <li>
                                Livestock are often moved between fields or, in larger areas, graze in patterns. Therefore you will
                                not be able to assess an extensive area but must select sufficiently different areas for assessment
                            </li>
                        </ul>
                        <h4>What you need:</h4>
                        <ul>
                            <li>Sample tray (cat litter trays greater than 40cm x 25cm are suitable)</li>
                        </ul>
                        <h4>What dung:</h4>
                        <ul>
                            <li>Dung without a glistening surface and not completely dry inside is suitable for assessment</li>
                            <li>Do not sample dung too liquid to pick up in pieces and add to the sample tray</li>
                            <li>Dung from each species present must be sampled according to the below protocol</li>
                        </ul>
                        <h4>How:</h4>
                        <ol>
                            <li>Select the location</li>
                            <li>Fill out the assessment form header and then for each dung pat complete a scorecard</li>
                            <li>Where possible take 6 dung pat samples from each species in each of the following:
                                <ol className="lower-alpha">
                                    <li>In sun (not shaded for a significant portion of the day)</li>
                                    <li>In shade (this can be the shaded side of a hedge or in a wood)</li>
                                </ol>
                            </li>
                            <li>Each dung sample (called a pat) = one scorecard:
                                <ol className="lower-alpha">
                                    <li>
                                        Each sample comprises of one cow pat, one horse pat or 12 sheep dung deposits (usually
                                        each one is in several pieces) or volume equivalent for other species.
                                    </li>
                                    <li>It is important to sample each livestock species present</li>
                                    <li>
                                        Place one pat at a time into the sample tray and where it touched the ground examine the
                                        area for beetle tunnels. The tunnels are recorded on the form
                                    </li>
                                    <li>
                                        Then work your way through the dung in the tray and count the number of beetles of each
                                        of the three types (see illustration) and record them on the form
                                    </li>
                                    <li>Return all dung and beetles back to where they were found</li>
                                    <li>
                                        Add up the scorecard for each pat, select the top scoring half of the pats then add just these up
                                        and divide by the number of top scoring pats. At the end of the survey put this number in the
                                        average site score box after multiplying it by 2 to get your percentage score.
                                    </li>
                                </ol>
                            </li>
                        </ol>
                        <h4>Scope:</h4>
                        <p>
                            This assessment methodology is designed to provide a very basic measure of the dung beetles at a site
                            for citizen science use. This assessment tool is designed to provide a rudimentary understanding of your
                            local dung beetle community. It is designed as a basic measure of dung beetle community health but it is
                            not designed to inform questions about biodiversity and abundance for wider use. This type of analysis is
                            not a substitute for a professional dung beetle diversity and abundance survey which is the domain of an
                            experienced specialist and important to inform land and livestock management discussions.
                        </p>
                        <h4>Circumstances:</h4>
                        <p>
                            There are a wide range of important issues associated with being active in managed and unmanaged
                            environments and it is assumed that prior to using this assessment method you (and your organisation)
                            have undertaken all appropriate and relevant precautions - including risk assessments, safety actions, risk
                            mitigations and permissions.
                        </p>
                    </section>
                    <div className="download-grid">
                        <Link to="https://www.dungbeetlesforfarmers.co.uk/downloads/Dung Beetle Assessment.pdf" title='Assessment Download' target="_blank">
                            <div>Instructions and Scorecards</div>
                            <img src={FilePDF} alt="PDF File" className="file-icon" />
                        </Link>
                        <Link to="https://www.dungbeetlesforfarmers.co.uk/downloads/Dung Beetle Assessment - Site Scorecard.pdf" title='scorecard download' target="_blank">
                            <div>Site Scorecard only</div>
                            <img src={FilePDF} alt="PDF File" className="file-icon" />  
                        </Link>
                        <Link to="https://www.dungbeetlesforfarmers.co.uk/downloads/Dung Beetle Assessment - Pat Scorecard.pdf" title='Pat scorecard only download' target="_blank">
                            <div>Pat Scorecard only</div>
                            <img src={FilePDF} alt="PDF File" className="file-icon" />
                        </Link>
                        <Link to="https://www.dungbeetlesforfarmers.co.uk/downloads/Dung Beetle Assessment Scorecard.xlsx" title='Excel scorecard download' target="_blank">
                            <div>Excel Scorecard</div>
                            <img src={FileXLSX} alt="Excel File" className="file-icon" />
                        </Link>
                    </div>        
                </div>
            </section>
        </article>
    </>
  );
}

export default App;