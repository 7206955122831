import React from 'react';
import {Link} from 'react-router-dom';

import './index.css';
import HeaderImage from "../../assets/images/banner-grid-square.webp";
import Vegetation from "../../assets/images/thumbnail-vegetation.webp";
import Insect from "../../assets/images/thumbnail-insect-2.webp";
import Bird from "../../assets/images/thumbnail-bird.webp";

function App() {
  const title = 'Biodiversity Surveys';
  document.title = global.AppName + ' - Surveys';
  return (
    <>
        <article id="main">
            <header style={{ backgroundImage: `linear-gradient(to top, rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(${HeaderImage})` }}>
                <h2>{title}</h2>
                <p>Vegetation, insect and bird surveying</p>
            </header>
            <section className="wrapper style5">
                <div className="inner">
                  <section>
                    <h3>Biodiversity Surveys</h3>
                    <p>
                      The Cotswold Parataxonomy Farming in a Protected Landscape (FiPL) project comprised of three different types
                      of biodiversity surveys that were run across the two pilot farms
                    </p>
                    <section className="features">
                      <section className="feature">
                        <Link to='/biodiversity-surveys/vegetation' title='Vegetation Surveys' target='_self'>
                          <img src={Vegetation} alt="Vegetation Survey" />
                        </Link>
                        <div>
                          <h4><Link to='/biodiversity-surveys/vegetation' title='Vegetation Surveys' target='_self'>Vegetation Surveys</Link></h4>
                          <p>
                            Vegetation surveying utilises the Grid Survey Methodology technique for mapping distribution of key flora species
                            across a site.
                          </p>
                          <Link to='/biodiversity-surveys/vegetation' title='Vegetation Surveys' target='_self'>Find out more...</Link>
                        </div>
                      </section>
                      <section className="feature">
                        <Link to='/biodiversity-surveys/insect' title='Insect Surveys' target='_self'>
                          <img src={Insect} alt="Insect Survey" />
                        </Link>
                        <div>
                          <h4><Link to='/biodiversity-surveys/insect' title='Insect Surveys' target='_self'>Flying Insect Surveys</Link></h4>
                          <p>
                            Flying insect surveys were run over two weeks using insect traps.
                          </p>
                          <Link to='/biodiversity-surveys/insect' title='Flying Insect Surveys' target='_self'>Find out more...</Link>
                        </div>
                      </section>                      
                      <section className="feature">
                        <Link to='/biodiversity-surveys/bird' title='Bird Surveys' target='_self'>
                          <img src={Bird} alt="Bird Survey" />
                        </Link>
                        <div>    
                          <h4><Link to='/biodiversity-surveys/bird' title='Bird Surveys' target='_self'>Bird Surveys</Link></h4>
                          <p>
                            Three bird surveys were undertaken on each pilot farm. One winter and two summer counts.
                          </p>
                          <Link to='/biodiversity-surveys/bird' title='Bird Surveys' target='_self'>Find out more...</Link>
                        </div>                      
                      </section>
                    </section>
                  </section>
                  <div className='clear'></div>
                </div>
            </section>
          </article>
    </>
  );
}

export default App;