import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import App from './App';
import './assets/css/main.css';
import ReactGA from 'react-ga4';

const TRACKING_ID = "G-38NG21FQ6J";
ReactGA.initialize(TRACKING_ID);

global.AppName = "Biodiversity Farms";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </React.StrictMode>
);