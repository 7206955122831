import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

// A custom component that scrolls to the top of the page
// when the location changes
const ScrollToTop = ({ children }) => {
  const location = useLocation();

  useEffect(() => {
    // Scroll to the top of the page
    window.scrollTo(0, 0);
  }, [location]); // Only run this effect when the location changes

  return children; // Return the children unchanged
};

export default ScrollToTop;