import React from 'react';
import { BrowserRouter, Route, Routes, Switch } from 'react-router-dom';
import Home from './pages/Home';
import Surveys from './pages/Surveys';
import Vegetation from './pages/Surveys/vegetation';
import Insect from './pages/Surveys/insect';
import Bird from './pages/Surveys/bird';
import DungBeetles from './pages/Surveys/dungbeetle';
import PilotFarms from './pages/PilotFarms';
import Farm1 from './pages/PilotFarms/farm-1';
import Farm2 from './pages/PilotFarms/farm-2';
import Conservation from './pages/Conservation';
import Experts from './pages/Experts';
import Bios from './pages/Experts/bios';
import Contact from './pages/Experts/contact';
import NotFound from './pages/Home/404';
import Template from './pages/Template';
import Header from "./components/header";
import Footer from "./components/footer";
import ScrollToTop from "./components/ScrollToTop";

const App = () => {
 return (
   <>
      <Header></Header>
      <ScrollToTop>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/biodiversity-surveys" element={<Surveys />} />
          <Route path="/biodiversity-surveys/vegetation" element={<Vegetation />} />
          <Route path="/biodiversity-surveys/insect" element={<Insect />} />
          <Route path="/biodiversity-surveys/bird" element={<Bird />} />
          <Route path="/biodiversity-surveys/dung-beetle" element={<DungBeetles />} />
          <Route path="/pilot-farms" element={<PilotFarms />} />
          <Route path="/pilot-farms/farm-1" element={<Farm1 />} />
          <Route path="/pilot-farms/farm-2" element={<Farm2 />} />
          <Route path="/conservation-measures" element={<Conservation />} />
          <Route path="/the-experts" element={<Experts />} />
          <Route path="/the-experts/bios" element={<Bios />} />
          <Route path="/the-experts/contact-us" element={<Contact />} />
          <Route path="/template" element={<Template />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </ScrollToTop>
      <Footer></Footer>
   </>
 );
};

export default App;